@font-face{
    font-family: 'm_black';
    src: local('m_black'), url('./ttf/m_black.ttf') format('truetype');
}

@font-face{
    font-family: 'm_bold';
    src: local('m_bold'), url('./ttf/m_bold.ttf') format('truetype');
}

@font-face{
    font-family: 'm_extra_light';
    src: local('m_extra_light'), url('./ttf/m_extra_light.ttf') format('truetype');
}

@font-face{
    font-family: 'm_light';
    src: local('m_light'), url('./ttf/m_light.ttf') format('truetype');
}

@font-face{
    font-family: 'm_medium';
    src: local('m_medium'), url('./ttf/m_medium.ttf') format('truetype');
}

@font-face{
    font-family: 'm_regular';
    src: local('m_regular'), url('./ttf/m_regular.ttf') format('truetype');
}

@font-face{
    font-family: 'm_semi_bold';
    src: local('m_semi_bold'), url('./ttf/m_semi_bold.ttf') format('truetype');
}

@font-face{
    font-family: 'marker';
    src: local('marker'), url('./ttf/marker.ttf') format('truetype');
}

@font-face{
    font-family: 'av_book';
    src: local('av_book'), url('./ttf/av_book.ttf') format('truetype');
}

@font-face{
    font-family: 'm_extra_bold';
    src: local('m_extra_bold'), url('./ttf/m_extra_bold.ttf') format('truetype');
}